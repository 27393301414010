<strong class="text-danger border border-2 border-danger rounded bg-danger px-2 " *ngIf="message.statusMessageData.registeredMail">{{'messages.actionsMessage.registeredMail.tooltip' | translate }}</strong>

<a *ngIf="message.forUser !==undefined" (click)="$event.stopPropagation()" href="javascript:void(0)" placement="left" triggers="click hover focus" [autoClose]="'outside'"
    ngbTooltip="{{'messages.people.'.concat(isLoggedUser(message.forUser.ssin)?'self' :'colleague') | translate}} {{!isLoggedUser(message.forUser.ssin)? ( message.forUser.label != undefined ? ': '.concat(message.forUser.label) :'') :''}}"
    class="{{isLoggedUser(message.forUser.ssin) ? 'active':''}}">
    <i class="fas fa-user" aria-hidden="true"
        [attr.aria-label]=" ( 'messages.people.'.concat(isLoggedUser(message.forUser.ssin)? 'self' : 'colleague') | translate).concat(!isLoggedUser(message.forUser.ssin)? ( message.forUser.label != undefined ? ': '.concat(message.forUser.label) :'') : '' )"></i></a>
<a *ngIf="message.replyStatusMessage != undefined && (message.replyStatusMessage.lastReplyDate == undefined || message.replyStatusMessage.lastReplyDate == null) && message.replyStatusMessage.canReply && folder != 'out'"
    (click)="$event.stopPropagation()"
    href="javascript:void(0)" placement="left" triggers="click hover focus" [autoClose]="'outside'" ngbTooltip="{{'messages.actionsMessage.reply.tooltip' | translate }}">
    <i class="fas fa-reply" aria-hidden="true" [attr.aria-label]="'messages.actionsMessage.reply.label' | translate"></i>
</a>
<a *ngIf="message.replyStatusMessage != undefined && message.replyStatusMessage.lastReplyDate != undefined && message.replyStatusMessage.lastReplyDate != null" (click)="$event.stopPropagation()" href="javascript:void(0)" placement="left"
    triggers="click hover focus"
    [autoClose]="'outside'" ngbTooltip="{{'messages.actionsMessage.reply.done' | translate:{value1:(message.replyStatusMessage.lastReplyDate  | date:'dd/MM/yyyy')} }}">
    <i class="fas fa-reply square-filled" aria-hidden="true" [attr.aria-label]="'messages.actionsMessage.reply.label' | translate"></i>
</a>
<a (click)="$event.stopPropagation()" *ngIf="dateIsExpired(message.expirationDate)" href="javascript:void(0)" placement="left" triggers="click hover focus" [autoClose]="'outside'"
    ngbTooltip="{{'messages.expiredDate.badge' | translate}} {{ isMobile ? '' : (message.expirationDate | date:'dd/MM/yy')}}">
    <span class="text-danger" appExpiredDateFC='{"publicationDate":"{{message.receiptDate }}","expirationDate":"{{message.expirationDate}}", "messageId":"{{message.messageId}}", "mobile":{{isMobile}} }' [refresh]="language"></span>
</a>