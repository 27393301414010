<header data-testid="header" #fixedHeader
    class="{{isMobile ? 'firstFixedHeader panelToFixed ' :''}} {{isMobile && dropMobile && dropMobile.isOpen() ? 'dropdownOpen' :'' }} {{hasAlert ? '':'no-alert'}}">
    <div
        class="container-fluid {{(isSearchBarVisible && isMobile ? 'search-expanded' : '')}}">
        <div
            class="row  {{isMobile ?'justify-content-between  align-items-center': 'equal-height-row'}} {{hasAllCompanyInformation() ? '' :'pb-md-4 pb-lg-0'}} pe-0 pe-sm-3">

            <div id="logo" class="col col-sm-3 col-lg-2">
                <a href="javascript:void(0)" (click)="logoReturn()">
                    <h1>
                        <img
                            [src]="isMobile ?'assets/images/e-Box_white_mobile.png' :'assets/images/e-Box_white.png'"
                            [width]="isMobile ?'95' :'154'" alt="logo e-Box" />
                        <span class="visually-hidden">{{'header.title' |
                            translate}}</span>
                    </h1>
                </a>
            </div>

            <div id="appDenomination"
                data-testid="header.companyFullDenomination"
                class="col-3 col-lg-4"
                *ngIf=" isUserLoaded && !errorLogin  && !isMobile && enterpriseUser!=null">

                <ng-container *ngTemplateOutlet="denomination"></ng-container>

            </div>

            <div id="tools"
                class="{{ errorLogin ? 'flex-grow-1' :'col-auto col-sm-6 col-lg-6 pe-0'}}">
                <nav class="navbar">
                    <ul class="navbar-nav navbar-expand-xl" *ngIf="!isMobile">
                        <li class="nav-item"
                            *ngIf="configService.getEnvironment() !== 'prod' && configService.getEnvironment() !== 'acc'">
                            <span class="badge text-bg-warning">{{configService.getEnvironment()}}</span>
                        </li>
                        <li class="nav-item external-link">
                            <ng-container
                                *ngTemplateOutlet="link; context : { $implicit : 'info' }"></ng-container>
                        </li>
                        <li class="nav-item external-link">
                            <ng-container
                                *ngTemplateOutlet="link; context : { $implicit : 'contact' }"></ng-container>
                        </li>

                        <li
                            class="nav-item external-link manage-ebox {{isOptinPage ? ' active' :''}}"
                            data-testid="header.optinLink"
                            *ngIf="isUserLoaded && !errorLogin && stateFnFe.isAccessConsultationAndPartitionUser()">
                            <ng-container
                                *ngTemplateOutlet="link; context : { $implicit : 'management' }"></ng-container>
                        </li>
                        <li class="break d-lg-none"></li>
                        <li data-testid="header.userName"
                            class="separator-left nav-item infoUser text-end text-xl-left text-nowrap"
                            *ngIf="isUserLoaded && !errorLogin  && enterpriseUser!=null"><span
                                class="d-inline-block"><i class="fas fa-user"
                                    aria-hidden="true"></i>{{enterpriseUser.firstName}}
                                {{enterpriseUser.lastName}}</span>
                        </li>
                        <ng-container *ngIf="isAuth">
                            <li class="nav-item logOut text-end text-lg-left "><a
                                    href='javascript:void(0)' (click)='logout()'
                                    data-testid="header.logout">{{'header.logout'
                                    | translate}}</a></li>
                        </ng-container>
                        <li class="nav-item dropdown text-end text-lg-left"
                            id="lang" data-testid="lang">
                            <a class="nav-link dropdown-toggle"
                                href="javascript:void(0)"
                                id="navbarLanguageMenuLink" role="button"
                                data-bs-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false" lang="{{language}}"
                                [title]="('header.language-titles.' + language) | translate">
                                {{language}}
                            </a>
                            <div class="dropdown-menu"
                                aria-labelledby="navbarLanguageMenuLink"
                                data-testid="lang.list">
                                <ng-container
                                    *ngFor="let lang of languageSupported">
                                    <a *ngIf="lang !==language"
                                        (click)='setLanguage(lang)'
                                        href="javascript:void(0)"
                                        class="dropdown-item" [lang]="lang"
                                        [title]="('header.language-titles.' + lang) | translate">{{lang}}</a>
                                </ng-container>
                            </div>
                        </li>
                    </ul>
                    <button
                        *ngIf="isUserLoaded && isMobile && !isSearchBarVisible && isConsultMessage"
                        class="d-sm-none navbar-toggler collapsed p-0"
                        type="button" role="button"
                        aria-controls="navbarsMobile-search"
                        (click)="toggleSearch() "
                        [attr.aria-expanded]="isSearchBarVisible">
                        <i class="fas fa-search text-white me-0"
                            aria-hidden="true"></i>
                        <span class="visually-hidden"
                            [innerHTML]='"header.search-bar.label" | translate'></span>
                    </button>
                </nav>
            </div>
            <ng-container *ngIf="isMobile">
                <div class="navigationMenuParams" ngbDropdown
                    #dropMobile="ngbDropdown">

                    <button class="navbar-toggler collapsed" type="button"
                        ngbDropdownToggle
                        (click)="isMenuParamsCollapsed = !isMenuParamsCollapsed;"
                        aria-controls="navigationParams"
                        [attr.aria-expanded]="isMenuParamsCollapsed"
                        attr.aria-label='{{"header.menu.label" | translate}}'
                        id="navigationParams">
                        <i class="fas fa-bars text-white me-0"
                            aria-hidden="true"></i>
                        <span class="visually-hidden">{{"header.menu.label" |
                            translate}}</span>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="navigationParams"
                        class="border-0 rounded-0 p-0 bg-transparent">

                        <div class="nav-content">
                            <div class="nav-content-header d-flex">
                                <div class="data-info flex-grow-1">
                                    <ng-container
                                        *ngTemplateOutlet="denomination"></ng-container>

                                </div>
                                <div class="close-dropdown">
                                    <button
                                        class="rounded-0 border-0 bg-transparent d-flex m-0 p-0"
                                        type="button" ngbDropdownToggle
                                        (click)="isMenuParamsCollapsed = !isMenuParamsCollapsed;">
                                        <i class="fas fa-times  me-0"
                                            aria-hidden="true"></i>
                                        <span class="visually-hidden">{{"header.menu.close"
                                            | translate}}</span>
                                    </button>
                                </div>
                            </div>
                            <ul class="navbar-nav">

                                <li class="border-bottom border-secondary mb-3">
                                    <app-leftnav
                                        [dropdownReference]="dropMobile"
                                        [isMobile]="isMobile"></app-leftnav>
                                </li>

                                <li class="nav-item external-link">
                                    <ng-container
                                        *ngTemplateOutlet="link; context : { $implicit : 'info' }"></ng-container>
                                </li>
                                <li class="nav-item external-link">
                                    <ng-container
                                        *ngTemplateOutlet="link; context : { $implicit : 'contact' }"></ng-container>
                                </li>

                                <li
                                    class="nav-item external-link manage-ebox {{isOptinPage ? ' active' :''}}"
                                    data-testid="header.optinLink"
                                    *ngIf="isUserLoaded && !errorLogin && stateFnFe.isAccessConsultationAndPartitionUser()">
                                    <ng-container
                                        *ngTemplateOutlet="link; context : { $implicit : 'management' }"></ng-container>
                                </li>

                                <li class="nav-lang ps-0 pe-0">
                                    <ul
                                        class="border-bottom border-secondary border-top list-inline">
                                        <li
                                            *ngFor="let lang of languageSupported"
                                            class="list-inline-item">
                                            <a
                                                [class]="(lang ==language ? 'active' :'')"
                                                (click)='setLanguage(lang);dropMobile.close()'
                                                href='javascript:void(0)'
                                                [lang]="lang"
                                                [title]="('header.language-titles.' + lang) | translate"
                                                [attr.data-testid]="'language-lang-'.concat(lang)">{{lang}}</a>
                                        </li>
                                    </ul>
                                </li>
                                <ng-container *ngIf="isAuth">
                                    <li class="nav-item text-center logout">
                                        <ng-container
                                            *ngTemplateOutlet="link; context : { $implicit : 'logout' }"></ng-container>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                        <div class="overlay"></div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="row"
            *ngIf=" isUserLoaded && isMobile && !errorLogin && isSearchBarVisible"
            #appSearchBar>
            <app-search-bar id="navbarsMobile-search"
                class="col col mt-0 pt-2 pb-2" [isMobile]="isMobile"
                (closeSearchMobile)="clearAndCloseSearch()"></app-search-bar>
        </div>

    </div>

</header>

<ng-template #link let-link>

    <ng-container [ngSwitch]="link">

        <a *ngSwitchCase="'info'" href="{{infoPath}}" target="_blank"
            title='{{"header.info" | translate}}' class="text-nowrap"
            (click)="close()">
            <i class="fas fa-info"></i>{{'header.info' | translate}}</a>
        <a *ngSwitchCase="'contact'"
            href="{{urlWebsiteInfo.concat('contactPath' | translate)}}"
            target="_blank" title='{{"header.contact" | translate}}'
            class="text-nowrap" (click)="close()"><i
                class="far fa-id-badge"></i>{{'header.contact' | translate}}</a>

        <a *ngSwitchCase="'management'" routerLink="/management"
            title='{{"header.management" | translate}}' class="text-nowrap"
            (click)="close()">
            <i class="fas fa-cog"></i>{{'header.management' | translate}}</a>
        <a *ngSwitchCase="'logout'" href='javascript:void(0)'
            (click)='logout();dropMobile.close()' data-testid="header.logout">{{'header.logout'
            | translate}}</a>

    </ng-container>

</ng-template>

<ng-template #denomination>
    <p data-testid="header.userName"
        *ngIf="isMobile && isUserLoaded && !errorLogin && enterpriseUser!=null">{{enterpriseUser.firstName}}
        {{enterpriseUser.lastName}}</p>
    <p class="enterpriseName"
        *ngIf="isUserLoaded && !errorLogin && enterpriseUser!=null && enterpriseUser.enterpriseName!= null">{{enterpriseUser.enterpriseName}}</p>
    <p *ngIf="enterpriseUser !=null">{{enterpriseUser.cbeNumber}}</p>
    <p class="text-nowrap d-inline-block"
        *ngIf="enterpriseUser!=null  && enterpriseUser.email !=null && enterpriseUser.email !=''"><i
            *ngIf="!isMobile" class="far fa-envelope" aria-hidden="true"></i>{{enterpriseUser.email}}

    </p>

</ng-template>
