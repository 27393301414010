<div *ngIf="message !==undefined">
    <h3 class="p-sm-0 mb-0 mb-sm-3 ms-sm-0">{{'form.label.bidirectional' |
        translate}}</h3>

    <form
        *ngIf="statusSendingForm === null || statusSendingForm == StatusFormsEnum.success || (statusSendingForm==StatusFormsEnum.error && bidirectionalForm.errors)"
        [formGroup]="bidirectionalForm" (ngSubmit)="onMailFormDataSubmit()"
        accept-charset="utf-8"
        [attr.aria-label]="'form.label.bidirectional'  | translate "
        class="w-100 {{(bidirectionalForm.status ==='INVALID') ? 'was-validated':''}}"
        method="post" enctype="multipart/form-data">

        <div class="alert alert-danger bg-transparent"
            *ngIf="bidirectionalForm.errors != null && bidirectionalForm.errors.bidirectionalFormHttpError != undefined"
            role="alert">
            <h4 class="alert-heading">{{'form.status.error' | translate}}</h4>
            <div class=" d-block d-sm-flex align-items-center mt-3 mb-3">
                <p>
                    <ng-container
                        *ngIf="bidirectionalForm.errors.bidirectionalFormHttpError.typeOfError=='empty'">
                        {{'form.message.error.empty' | translate}}
                    </ng-container>
                    <ng-container
                        *ngIf="bidirectionalForm.errors.bidirectionalFormHttpError.typeOfError=='mimetype' || bidirectionalForm.errors.bidirectionalFormHttpError.typeOfError=='maxSize'">
                        {{'form.file.error.'+bidirectionalForm.errors.bidirectionalFormHttpError.typeOfError
                        |
                        translate:{value1:(bidirectionalForm.errors.bidirectionalFormHttpError.args
                        == undefined?'':': '.concat(bidirectionalForm.errors.bidirectionalFormHttpError.args[0]))}
                        }}
                    </ng-container>
                    <ng-container
                        *ngIf="bidirectionalForm.errors.bidirectionalFormHttpError.typeOfError=='error'">
                        {{'form.status.generalError' | translate}}
                    </ng-container>
                </p>
            </div>
        </div>

        <div class="form-group col-lg-6 col-xl-4">
            <label for="phoneNumberInput" id="labelPhoneNumber"
                [class]="(bidirectionalForm.controls.phoneNumberInput.errors && bidirectionalForm.controls.phoneNumberInput.errors.phoneNumberError) ? 'invalid-field' : null ">
                <span>{{'form.tel.label' | translate}}</span>
                <div role="alert" class="invalid-feedback" id="errorPhoneNumber">
                    <ng-container
                        *ngIf="bidirectionalForm.controls.phoneNumberInput.errors && bidirectionalForm.controls.phoneNumberInput.errors.phoneNumberError">
                        <span class="d-block">({{'form.tel.error.errorFormat' |
                            translate }})</span>
                    </ng-container>
                </div>
            </label>
            <div class="input-group">
                <input type="text" class="form-control" id="phoneNumberInput"
                    aria-labelledby="labelPhoneNumber errorPhoneNumber"
                    formControlName="phoneNumberInput" autocomplete="true" />
            </div>
        </div>
        <div class="form-group col-xl-8">
            <label for="textareaId" id="labelTextareaId"
                [class]="(bidirectionalForm.errors !== null && bidirectionalForm.errors.messageTextarea !== null && bidirectionalForm.controls.messageTextarea.status =='INVALID') ? 'invalid-field' : null ">
                <span>{{'form.message.label' | translate}} ({{'form.required.label'
                    | translate}})</span>
                <div role="alert" class="invalid-feedback" id="errorMessage">
                    <ng-container
                        *ngIf="bidirectionalForm.errors !== null && bidirectionalForm.errors.messageTextarea !== null && bidirectionalForm.controls.messageTextarea.status =='INVALID'">
                        <span class="text-block">{{'form.message.error.empty' |
                            translate}}</span>
                    </ng-container>
                </div>
            </label>
            <textarea formControlName="messageTextarea" cols="38" rows="9"
                aria-labelledby="labelTextareaId errorMessage" id="textareaId"
                class="form-control" [title]="'form.message.label' | translate"></textarea>
        </div>
        <div class="form-group col">
            <label
                [class]="(bidirectionalForm.controls.attachmentMessage.errors && bidirectionalForm.controls.attachmentMessage.errors.attachmentMessageError) ? 'invalid-field' : null ">
                <span class="me-1">{{'form.file.label' | translate}}</span>
                <a href="javascript:void(0)" placement="top-left"
                    triggers="click" [autoClose]="'outside'"
                    [ngbTooltip]="tipMimetype"
                    class="d-inline-block align-items-center justify-content-center me-1"
                    aria-describedby="tipMimetype">
                    <sup><i class="fas fa-info-circle fa-lg" aria-hidden="true"
                            [attr.aria-label]="'form.file.mimetype' | translate"></i></sup>
                    <ng-template #tipMimetype id="tipMimetype">{{('form.file.mimetype'
                        | translate).concat(': ').concat(mimeTypeAuthorized.join(', '))}}</ng-template>
                </a>
                <div role="alert" class="invalid-feedback"
                    id="errorAttachmentMessage">
                    <ng-container
                        *ngIf="bidirectionalForm.controls.attachmentMessage.errors && bidirectionalForm.controls.attachmentMessage.errors.attachmentMessageError">
                        <span class="d-block"
                            *ngFor="let error of bidirectionalForm.controls.attachmentMessage.errors.attachmentMessageError">{{'form.file.error.'+error.typeOfError
                            | translate:{value1:" ".concat(error.args[0])} }}</span>
                        <span class="d-block"
                            *ngFor="let error of bidirectionalForm.controls.attachmentMessage.errors && bidirectionalForm.controls.attachmentMessage.errors.bidirectionalFormHttpError">
                            <ng-container *ngIf="error.args.length == 1">
                                {{'form.file.error.'+error.typeOfError |
                                translate:{value1:': \"'.concat(error.args[0]).concat('"')}
                                }}
                            </ng-container>
                            <ng-container *ngIf="error.args.length > 1">
                                {{'form.file.error.'+error.typeOfError |
                                translate:{value1:''} }} :
                                <ul>
                                    <li *ngFor="let arg of error.args">
                                        "{{arg}}"
                                    </li>
                                </ul>
                            </ng-container>
                        </span>
                    </ng-container>
                </div>
            </label>
            <div class="mt-1">
                <input
                    [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                    formControlName="attachmentMessage"
                    aria-labelledby="errorAttachmentMessage customFileLang"
                    type="file" (change)="onFileChange($event)" class="d-none"
                    id="customFileLang"
                    [lang]="language" multiple />

                <label
                    [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                    for="customFileLang" id="labelCustomFileLang"
                    class="d-inline-block ">
                    <span tabindex="0"
                        [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                        class="btn btn-secondary {{ attachments != null && attachments.length>=maxNumberFile ? 'disabled' : ''}}">{{'form.file.button'
                        | translate}}</span>
                </label>

                <small
                    class="mt-2 mt-lg-0 ms-lg-2 form-text text-muted d-inline-block"><span
                        [innerHTML]="'form.file.maxSize.enterprise' | translate:{value1:maxSizeFile,value2:maxNumberFile}"></span></small>
                <ul *ngIf="attachments != undefined && attachments.length > 0"
                    class="attachmentListForm list-unstyled g-0 mt-2">
                    <li *ngFor="let file of getAttachments(); let i = index"
                        class="list-inline-item">
                        <a [href]="sanitize('javascript:void(0)')"
                            (click)="removeAttachment(i)"
                            class="d-flex justify-content-between align-items-center bg-white"
                            [title]="file.fileName">
                            <app-attachment [attachment]="file"
                                class="d-inline-flex"></app-attachment>
                            <span class="file-size text-end pe-0 ps-0">({{file.fileSize
                                | fileSize}})</span>
                            <i class="fas fa-trash-alt text-primary ms-2"
                                aria-hidden="true"></i>
                            <span class="visually-hidden">{{'form.file.remove' |
                                translate }}</span></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col">
            <button [disabled]="errorsForms" type="submit"
                class="btn btn-primary"
                [title]="'form.action.submit.label' | translate"><i
                    class="far fa-envelope"></i> {{'form.action.submit.label' |
                translate}}
            </button>
            <a href="javascript:void(0)" class="btn btn-link"
                (click)="closeForms()">{{'form.action.cancel' | translate}}</a></div>
    </form>
    <div *ngIf="statusSendingForm == StatusFormsEnum.failed"
        class="col-12 p-sm-0">
        <div role="alert" class="alert alert-danger  bg-white mt-2 col-xl-8 ">
            <p>{{'form.status.'+(statusSendingForm == StatusFormsEnum.success ?'success':'generalError')
                | translate}}</p>
        </div>
    </div>
</div>
