import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ActionsService, LoadingService, ResponsiveService, SetPositionFixedService } from '../../services';
import { EnterpriseUser } from '../../models/enterprise-user';
import { ApplicationConstants } from '../../app.constants';

import { OpenIdService } from '../../auth/openIdService';
import { StateFn } from "../../store/StateFn";

import { ConfigurationService } from '@smals/ngx-configuration-service';

import { Subscription } from 'rxjs';
import { NGXLogger } from "ngx-logger";
import { ActionsStore } from '../../store/actions-store';
import {
  PaginationParameters
} from '@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData';
import { VisibilityStatus } from '../../store/store-data-interface';
import { StoreService } from '../../store/store.service';
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { CacheStorageService } from '../../services/cacheStorage.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public isMobile: boolean;
  @Input()
  public isUserLoaded: boolean;
  isConsultMessage: boolean;

  numberOfSearchResult: number

  @ViewChild('fixedHeader') fixedHeader: ElementRef;

  @ViewChild('appSearchBar') appSearchBar: ElementRef;


  public language: string;
  isSearchBarVisible: boolean;
  isFilterCollapsed: boolean;
  isMenuParamsCollapsed: boolean;

  public stateFnFe: StateFn;
  public isOptinPage: boolean;

  private _pathWs: string;
  public _stateMaskedMessage: boolean;
  searchIsActive: boolean;
  private subscriptions: Subscription[] = [];
  infoPath = '';
  hasScopeManualPublication: boolean;
  hasAlert: boolean;
  private _errorLogin: boolean;
  urlWebsiteInfo = '';
  @Input()
  set errorLogin(errorLogin: boolean) {
    this._errorLogin = errorLogin;
  }

  @ViewChild('dropMobile') dropMobile: NgbDropdown;


  constructor(private logger: NGXLogger,
    private _changeDetector: ChangeDetectorRef,
    private openIdService: OpenIdService, private _configService: ConfigurationService,
    private positionFixedService: SetPositionFixedService, private stateFn: StateFn, public actionsService: ActionsService, private _actionsStore: ActionsStore, private storeService: StoreService, private _responsiveService: ResponsiveService, private _cacheStorageService: CacheStorageService, private _loadingService: LoadingService) {

    this.pathWs = this._configService.getEnvironmentVariable('urlBackendEnterpriseRest');
    this.urlWebsiteInfo = this.configService.getEnvironmentVariable('urlWebsiteInfo');
    this.infoPath = this.urlWebsiteInfo;
    this.hasScopeManualPublication = false;
    this.hasAlert = false;
    this.stateFnFe = stateFn;
    this.language = this.stateFn.getLanguage();
  }

  ngOnInit() {
    this.isSearchBarVisible = false;
    this.isFilterCollapsed = false;
    this.isMenuParamsCollapsed = false;

    this.subscriptions.push(this.stateFn.register(s => {
      this.isMobile = (!this.stateFn.isMobile() && this._responsiveService.getScreenWidth() == 'sm') ? true : this.stateFn.isMobile();
      this.hasScopeManualPublication = this.stateFn.isAccessManualPublication();
      this.language = this.stateFn.getLanguage();

      this.numberOfSearchResult = this.stateFn.getNumberOfSearchResults();
      if (s.userSession.user !== null) {
        this.enterpriseUser = s.userSession.user
      }
      if (s.searchState.searchResults != null) {
        this.numberOfSearchResult = s.searchState.searchResults.totalItems;
      }
      this.isOptinPage = this.stateFn.isCurrentPage('optin');


      this.infoPath = this.urlWebsiteInfo + "/" + this.language + "/index.html";

      this.searchIsActive = s.messageFilters.q != null;
      this.isConsultMessage = this.stateFn.isCurrentPage("messages");
      this.isSearchBarVisible = (this.isSearchBarVisible || this.searchIsActive) && this.isConsultMessage;
      if (this.isMobile && !this.isConsultMessage) {
        this.updateOrResetPositionFixedElement(null);
      }

    }));


  }

  ngAfterViewInit() {

    this.positionFixedService.addElement(
      { idPanelToFixed: 'fixedHeader', elemntRef: this.fixedHeader, positionInit: this.fixedHeader.nativeElement.getBoundingClientRect().top }
    );
    const s = this.storeService.store.getValue()

    if (this.hasAlert != (Object.keys(s.alerts).length > 0)) {
      this.logger.info("this.alert " + this.hasAlert);
      this.hasAlert = Object.keys(s.alerts).length > 0;
    }
  }

  setLanguage(lang) {
    this.actionsService.changeLanguage(lang);
  }


  public logout() {

    this.openIdService.logout().
      then(v => { // Navigate to logout url
      })
      .catch(e => {
        this.logger.error('logout', e);
      }).then(rest => {
        this.openIdService.reset();
      }).then(v => {
        const language = this.stateFn.getLanguage();
        sessionStorage.clear();

        this._cacheStorageService.clearLocalStorageAndKeepOptin(this.storeService.store.getValue().userSession?.user?.cbeNumber);

        this.actionsService.changeLanguage(language);

        this._actionsStore.removeAlert('error');
        const urlLogout = this._configService.getEnvironmentVariable('end_session_endpoint') + '?target=' + this._configService.getEnvironmentVariable('urlWebsiteInfo') + '/' + this.language + '/index.html';
        window.location.href = urlLogout;
      });


    /*
          Logout redirect vers https://oauth-v3.int.ext.socialsecurity.be/oauth2/logout with target wait update certif by bosa

    */
  }
  public get configService() {
    return this._configService;
  }
  public get isAuth() {
    return this.stateFn.isAccessTokenValid();
  }



  public get paginationProperties(): PaginationParameters {
    return this.stateFn.getParametersClient().pagination;
  }

  public get pathWs(): string {
    return this._pathWs;
  }

  public set pathWs(value: string) {
    this._pathWs = value;
  }

  public get languageSupported() {
    return ApplicationConstants.LANGUAGE_SUPPORTED;
  }

  public get stateMaskedMessage(): boolean {
    return this.storeService.store.getValue().messageFilters.visibility != VisibilityStatus.visible;
  }

  public set stateMaskedMessage(value: boolean) {
    throw new Error("Aha so this how it is done. Ok then let's call the state");
  }

  public enterpriseUser: EnterpriseUser;

  ngOnDestroy() {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }


  get errorLogin(): boolean {
    return this._errorLogin;
  }

  toggleSearch() {
    if (this.isSearchBarVisible) {

      this.updateOrResetPositionFixedElement(null);
      this.isSearchBarVisible = false;
    } else {

      this.isSearchBarVisible = true;
      this._changeDetector.detectChanges();
      this.updateOrResetPositionFixedElement(this.appSearchBar.nativeElement.getBoundingClientRect().height);
    }


  }
  clearAndCloseSearch() {
    this.toggleSearch();
  }
  updateOrResetPositionFixedElement(height: number) {
    if (height == null) {
      this.positionFixedService.resetElement("fixedLeftnav");
      this.positionFixedService.resetElement("fixedAlert");
    } else {
      this.positionFixedService.updateElement("fixedLeftnav", height);
      this.positionFixedService.updateElement("fixedAlert", height);
    }

    this.positionFixedService.refreshPositioning();
  }

  close() {
    if (this.isMobile) {
      this.dropMobile.close()
    }
  }

  logoReturn() {
    if (this.stateFn.isAccessManualPublication() && (this.stateFn.isAccessPartition() || this.stateFn.isAccesPartitonOldScopes()) && !this.stateFn.isAccessConsultation()) {
      this.actionsService.clearSearchAndMessageFiltersAndPage("manualPublishMessage");
    } else {
      this._loadingService.start({ serviceRunStart: 'logoReturn', delayedStart: false })
      this.actionsService.clearSearchAndMessageFiltersAndPage();
    }

  }
  hasAllCompanyInformation(){
    return this.enterpriseUser!=null && this.enterpriseUser.enterpriseName!= null && this.enterpriseUser.email
}
}
