
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { NGXLogger } from "ngx-logger";
import { ActionsStore } from '../store/actions-store';
import { StateFn } from '../store/StateFn';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { HandleExceptionService } from '../error/handle-exception-service.service';
import { Router } from '@angular/router';

export const OAUTH_CALLBACK_PATHNAME = '/oauthCallback';
export const LOGIN_PATHNAME = '/login';

export const LOCATION_AFTER_LOGIN_URL_KEY = 'locationAfterLogin';

export interface OpenIdProfile {
  social_security_identification_number: string;
  family_name: string;
  given_name: string;
  enterprise_number: string;

}

export interface AppLocation {
  pathName: string;
  queryParams: URLSearchParams;
  fragment: string | null;
}

export enum AuthenticationError {
  unReadUser = 'Could not read user',
  badState = 'Bad state, should not call process state more than once',
  invalidScopes = 'Invalid scopes',
  tokenHasClockNotSynchronized = 'Clock local is not synchronize'

}

@Injectable()
export class OpenIdService {

  stateProcessed: any;

  private isAuthenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private logger: NGXLogger, private _router: Router, private actionsStore: ActionsStore, private stateFn: StateFn, private oidcSecurityService: OidcSecurityService, private _handleExceptionService: HandleExceptionService) {


  }

  private scopes: string[];


  signinRedirect(authorizationEndpoint) {
    return this.oidcSecurityService.authorize(null, {
      customParams: {
        ...authorizationEndpoint
      }
    } as AuthOptions)
  }
  clearStaleState(): any {
    return this.oidcSecurityService.logoffLocal();

  }
  removeUser(): any {
    return this.oidcSecurityService.logoff()
  }
  revokeAccessToken() {
    return this.oidcSecurityService.revokeAccessToken().toPromise();
  }




  logout() {
    return this.oidcSecurityService.logoffAndRevokeTokens().toPromise();
  }

  reset() {
    this.isAuthenticatedUser.next(false);
    function clearLocalData() {
      this.profile = null;
      sessionStorage.removeItem(LOCATION_AFTER_LOGIN_URL_KEY);
      return Promise.all([/*this.oidc.clearStaleState(), this.oidc.removeUser()*/]);
    }

    return this.revokeAccessToken()
      .then(clearLocalData.bind(this))
      .catch(e => {
        // If we fail to logout, we should try to delete the local storage but still report the issue
        clearLocalData.call(this);
        throw e;
      });
  }


  public oidcCompleteStoreO(user: OpenIdProfile, accessTokenValue, scopesToProcess): Observable<boolean> {
    try {
      this.oidcCompleteStore(user, accessTokenValue, scopesToProcess);
      return of(true);

    } catch (error) {
      return throwError(() => error);
    }
  }

  public oidcCompleteStoreP(user: OpenIdProfile, accessTokenValue, scopesToProcess): Promise<boolean> {
    this.oidcCompleteStore(user, accessTokenValue, scopesToProcess);

    return Promise.resolve(true);
  }
  private oidcCompleteStore(user: OpenIdProfile, accessTokenValue, scopesToProcess) {
    this.scopes = this.parseFallbackScopes(scopesToProcess);
    this.scopesValidation();
    try {
      this.actionsStore.oidcLoginCompleted(user, accessTokenValue);
      this.isAuthenticatedUser.next(true);
    } catch (error) {
      throw new Error(AuthenticationError.unReadUser);
    }

  }

  public updateAccessToken(accessToken) {
    this.logger.info("updateAccessToken", accessToken);

    this.actionsStore.oidcUpdateAccessToken(accessToken);
  }



  private scopesValidation(): void {
    const scopes: string[] = this.scopes;
    if (scopes.length === 0) scopes.push(...this.stateFn.getScopes());
    this.actionsStore.addScopes(scopes);
    if (!this.stateFn.isAccessConsultationAndPartitionUser() || this.stateFn.isAccessOnlyManualPublication()) {
      if (this.stateFn.isAccessOnlyManualPublication()) {
        if (this._router.url != '/manualPublishMessage') {
          this._router.navigate(["/manualPublishMessage"], { replaceUrl: true });
        }
      } else {
        this.logger.error('forbidden scopes validation');
        throw new Error(AuthenticationError.invalidScopes);
      }

    }
  }


  isAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedUser.asObservable();
  }


  private parseFallbackScopes(scopeUri?: string): string[] {
    try {
      const scopes = scopeUri ?? window.location.hash.split("scope=")[1].split("&")[0];
      const decodeScopeUri = decodeURIComponent(scopes);
      const scopesDecoded = (decodeScopeUri.includes('+')) ? decodeScopeUri.split("+") : decodeScopeUri.split(" ");
      return scopesDecoded;
    } catch (e) {
      this.logger.error('parseAccessToken', e);
      try {
        const error = window.location.hash.split("error=")[1].split("&")[0];
        if (error == 'access_denied') {
          this._handleExceptionService.forbiddenAccess();
        }
      } catch (error) {
        throw new Error(AuthenticationError.invalidScopes);

      }
      return null;
    }
  }
}

