<div class="managementPage">
    <div
        class="{{isMobile ? 'd-block mt-1': 'd-flex align-items-center'}} border-bottom">
        <h2 class="ps-0 pt-0 pb-0  m-0 ">{{'management.title' | translate}}</h2>
    </div>
    <div class='optin' *ngIf="storeIsLoaded">

        <div
            *ngIf="resultForm!=null && resultForm.statusForms !=null && (resultForm.statusForms==StatusFormsEnum.success || resultForm.statusForms==StatusFormsEnum.failed)"
            role="alert"
            class="alert alert-{{resultForm.statusForms==StatusFormsEnum.success ?'success':'danger'}} w-100 mt-2  text-body ms-0 me-sm-0 pt-2 pb-2 border-0">
            <p>{{'management.optin.email.form.status.'+(resultForm.statusForms==StatusFormsEnum.success
                ? 'success':'error') | translate}}</p>
        </div>
        <ng-container *ngIf="isEmailSet()">
            <h3>{{'management.optin.sub-title-email' | translate}}</h3>
            <app-email-update class="d-block"
                [accessReadOnly]="isAccessMailReadOnly()"></app-email-update>
        </ng-container>
        <h3 class="mt-3">{{'management.optin.sub-title-'.concat(optinAuthorized)|
            translate}}</h3>
        <p class="text-justify" *ngIf="optinAuthorized"
            [innerHTML]="'management.optin.text.true' | translate"></p>

        <p
            *ngIf="(optinAuthorized == null || !optinAuthorized) && isAccessReadOnly()"
            class="text-justify">{{'management.optin.text.false.readonly' |
            translate}}</p>
        <ng-container *ngIf="!isAccessReadOnly()">
            <ng-container *ngIf="optinAuthorized == null || !optinAuthorized">
                <p class="text-justify mb-4"
                    [innerHTML]="'management.optin.text.false' | translate">
                </p>
                <div class="alert alert-info mb-4" role="alert">
                    <p class="text-justify"
                        [innerHTML]="'management.optin.text.warning' | translate"></p>
                </div>
            </ng-container>
            <p class="text-start "
                *ngIf="!optinAuthorized && !isAccessReadOnly()">
                <button [disabled]="optinAuthorized " type="button"
                    class="btn btn-primary" (click)="onAccept()">{{(optinAuthorized
                    ? 'management.optin.buttonText.true' :
                    'management.optin.buttonText.false') | translate}}</button>
            </p>
        </ng-container>
    </div>

    <div class="pacman" *ngIf="storePartitionisLoaded && isAdmin">
        <h3 [innerHTML]="'pacman.management.title' | translate"></h3>
        <ng-container *ngIf="partitionPolicyAccepted">
            <p class="mb-0" [innerHTML]="'pacman.management.textContent-accepted' | translate"></p>
            <p>
                <a class="text-underline" routerLink='./partitions'>> {{'pacman.management.button'|translate}}</a>
            </p>
        </ng-container>
        <ng-container *ngIf="!partitionPolicyAccepted">
            <p class="mb-0" [innerHTML]="'pacman.management.textContent-not-accepted' | translate"></p>
            <p>
                <a class="text-underline" routerLink='./acceptPartitionPolicy'>> {{'pacman.management.button'|translate}}</a>
            </p>
        </ng-container>
    </div>

    <div class="pacman" *ngIf="isAdmin">
        <h3 [innerHTML]="'management.docconsumer.title' | translate"></h3>
        <ng-container *ngIf="docConsumerPolicyAccepted">
            <p class="mb-0" [innerHTML]="'management.docconsumer.textContent-accepted' | translate"></p>

        </ng-container>
        <ng-container *ngIf="!docConsumerPolicyAccepted">
            <p class="mb-0" [innerHTML]="'management.docconsumer.textContent-not-accepted' | translate"></p>
            <p>
                <a class="text-underline" routerLink='./docConsumerPolicy'>> {{'management.docconsumer.button'|translate}}</a>
            </p>
        </ng-container>
    </div>

</div>
