import moment from "moment";
import { ApplicationConstants } from "../app.constants";
import { Injectable } from "@angular/core";
import { StoreService } from "./store.service";
import {
  AuthenticationState,
  Folder,
  PacmanDataForms,
  ReferencesDataState,
  SearchResults,
  SearchState,
  StoreShape,
  VisibilityStatus,
} from "./store-data-interface";
import { Scopes } from "../configuration-data";
import { RegistriesApi } from "../registries/registries-api";

import { CommonException } from "../error/CommonException";
import { Partition } from "../pacman/interfaces/partition";
import { TranslateService } from "@ngx-translate/core";
import { Registry } from "@smals/ebox-enterprise-client/dist/src/external-lib/ServiceClient/RegistriesConfig";
import {
  LocaleParameter,
  Parameters,
  SearchParameters,
  SenderOrganizationWithRegistryConfig
} from "@smals/ebox-enterprise-client/dist/src/external-lib/InterfaceData";
import { LocalizedString } from '@smals/ebox-enterprise-client/dist/src/external-lib/client_api';
import { MessageRegistry } from "../registries/interface/Message-Registry";
import { LocalRegistry } from "../registries/configuration-registries";

@Injectable()
export class StateFn {



  constructor(private storeService: StoreService, _translateService: TranslateService, private appConstants: ApplicationConstants) {
    //RegistriesConfig._translateService = _translateService;

    (window as any).appState = () => storeService.store.getValue();
    const registry = new RegistriesApi();
    registry.init(_translateService, this.storeService);
    (window as any).app = registry;
    (window as any).showLog = storeService.store.getValue().subsystems.showErrorInConsoleLog = true;
  }
  isDpLocal() {
    return this.storeService.store.getValue().subsystems.useDpFromStorage;
  }
  getDataForms() {
    return this.storeService.store.getValue().userActions.dataForms;
  }

  showLogInConsole(): boolean {
    return this.storeService.store.getValue().subsystems.showErrorInConsoleLog;
  }

  isOpenIdActive() {
    return this.storeService.store.getValue().subsystems.openId
  }

  getError(): CommonException {
    return this.storeService.store.getValue().subsystems.error;
  }

  isAdmin(): boolean {
    return this.storeService.store.getValue().userSession.isAdmin;
  }

  isAccessTokenValid() {
    return this.storeService.store.getValue().userSession.accessToken !== null && this.storeService.store.getValue().userSession.accessToken !== '' && !this.storeService.store.getValue().tokenIsInvalid
  }

  isForbiddenAccess() {
    return this.storeService.store.getValue().userSession.stepAuthentication == AuthenticationState.error;
  }
  getAuthenticationState() {
    return this.storeService.store.getValue().userSession.stepAuthentication;
  }

  getUser() {
    return this.storeService.store.getValue().userSession.user;
  }
  getConfigClient() {
    return this.storeService.store.getValue().userSession.configClient;
  }

  getScopes(): string[] {
    return this.storeService.store.getValue().userSession.scopes;
  }
  isAccessManualPublication() {
    const scopes = this.getScopes();
    //return true;
    return (scopes != null && scopes.includes(Scopes.EBOX_PUBLICATION_WEB));
  }

  isAccessOnlyManualPublication() {
    return this.isAccessManualPublication() && !this.isAccessConsultation();
  }

  isAccessOptinAndEmailModification() {
    const scopes = this.getScopes();
    //return true;
    return (scopes != null && (scopes.includes(Scopes.ADMIN)));
  }

  isAccessPartition() {
    const scopes = this.getScopes();
    //return true;
    return (scopes != null && (scopes.includes(Scopes.EBOX_RESPONSIBLE) || scopes.includes(Scopes.EBOX_ENTERPRISE_ADMIN) || scopes.includes(Scopes.EBOX_ENTERPRISE_USER) || scopes.includes(Scopes.EBOX_MANDATARY_ADMIN) || scopes.includes(Scopes.EBOX_MANDATARY_USER)));
  }
  isAccesPartitonOldScopes() {
    const scopes = this.getScopes();
    //return true;
    return (scopes != null && scopes.includes(Scopes.EBOX_PARTITION_USER));
  }


  isAccessReadOnly() {
    return this.isAccessManualPublication() && !this.isAccessConsultation() && (this.isAccessPartition() || this.isAccesPartitonOldScopes());
  }

  isAccessAdmin() {
    const scopes = this.getScopes();
    return (scopes != null && (scopes.includes(Scopes.EBOX_RESPONSIBLE) || scopes.includes(Scopes.EBOX_ENTERPRISE_ADMIN) || scopes.includes(Scopes.EBOX_MANDATARY_ADMIN)));
  }

  isAccessConsultationOldScopes() {
    const scopes = this.getScopes();
    //return true;
    return (scopes != null && (
      scopes.includes(Scopes.MESSAGES_FULL) && scopes.includes(Scopes.SUMMARY_OWN_EBOX) && scopes.includes(Scopes.REFERENCE_DATA))
    );
  }
  isAccessConsultation() {
    return this.isAccessPartition() || this.isAccessConsultationOldScopes();
  }

  getConfigRegistriesLoaded(): Registry[] {
    if (this.storeService.store.getValue().userSession.configClient != null && this.storeService.store.getValue().userSession.configClient.registries != null) {
      return this.storeService.store.getValue().userSession.configClient.registries;
    };
    return null;
  }

  isAccessConsultationAndPartitionUser() {
    return this.isAccessConsultation() || this.isAccesPartitonOldScopes();
  }

  convertStrToFolder(folder: string): Folder {
    switch (folder) {
      case "in": return Folder.in;
      case "out": return Folder.out;
      case "hide": return Folder.hide;
      default:
        break;
    }
    return null;
  }
  convertFolderToStr(folder: Folder): string {
    switch (folder) {
      case Folder.in: return 'in';
      case Folder.out: return 'out';
      case Folder.hide: return 'hide';
      default:
        break;
    }
    return null;
  }

  getCurrentFolder(): Folder {
    if (this.storeService.store.getValue().messageFilters.visibility == VisibilityStatus.invisible) {
      return Folder.hide;
    } else {
      return this.storeService.store.getValue().messageFilters.folder;
    }
  }
  getCurrentPage(): string {
    return this.storeService.store.getValue().userActions.currentPage;
  }

  isCurrentPage(page: string) {

    return this.getCurrentPage() == page;
  }




  // TODO make this private
  getParametersClient(): Parameters {
    const filters = this.storeService.store.getValue().messageFilters;
    const searchState = this.storeService.store.getValue().searchState;

    const searchParams: Partial<SearchParameters> = {
      senderApplication: null,
      senderOrganization: null,
    };

    if (filters.q != null) {
      searchParams.q = filters.q;
    }


    searchParams.receivedDate = {
      before: filters.publicationDateTo,
      after: filters.publicationDateFrom,
    }

    if (filters.visibility != null) {
      switch (filters.visibility) {
        case VisibilityStatus.all: break; // No param for this case
        case VisibilityStatus.visible: searchParams.visible = true; break;
        case VisibilityStatus.invisible: searchParams.visible = false; break;
      }
    }
    // Read unread filters
    if (filters.includeReadMessages == filters.includeUnreadMessages) {
      searchParams.readStatus = null;
    }
    else if (filters.includeUnreadMessages) {
      searchParams.readStatus = false;
    }
    else if (filters.includeReadMessages) {
      searchParams.readStatus = true;
    }

    if (filters.includeExpiringMessages) {
      const expDate = moment().add(ApplicationConstants.offDay, "d").toDate();
      searchParams.expirationDate = {
        before: expDate
      };
    }

    if (filters.includeRegisteredMessages) {
      searchParams.registeredMail = true;
    }


    if (filters.senderApplicationSelection != null) {
      searchParams.senderApplication = {
        id: filters.senderApplicationSelection.split("_rid_")[0],
        registryId: filters.senderApplicationSelection.split("_rid_")[1]
      }
    }

    if (filters.senderOrganizationSelection != null) {
      searchParams.senderOrganization = {
        id: filters.senderOrganizationSelection.split("_rid_")[0],
        registryId: filters.senderOrganizationSelection.split("_rid_")[1]
      }
    }
    if ((filters.folder == Folder.in || filters.folder == Folder.out) && VisibilityStatus.invisible != filters.visibility) {
      searchParams.folder = this.convertFolderToStr(filters.folder);
      if (filters.folder == Folder.out) searchParams.visible = null;
    }
    if (filters.recipientPartition != null) {
      searchParams.recipientPartition = filters.recipientPartition;
    }
    if (filters.senderPartition != null) {
      searchParams.senderPartition = filters.senderPartition;
    }
    if (filters.includeRequiredAction != null) {
      searchParams.requiredAction = filters.includeRequiredAction;
    }

    let apiLang: LocaleParameter = null;
    switch (this.storeService.store.getValue().userSession.localeParameter) {
      case "fr": apiLang = LocaleParameter.fr; break;
      case "nl": apiLang = LocaleParameter.nl; break;
      case "de": apiLang = LocaleParameter.de; break;
      case "en": apiLang = LocaleParameter.en; break;
    }

    return {
      pagination: {
        // TODO page number should not be in pagination parameters
        page: 1,
        pageSize: searchState.pageSize,
      },
      localeParameter: apiLang,
      search: {
        ...searchParams
      }
    };
  }

  isAttemptConnection() {
    const numberConnection = localStorage.getItem('attemptLastConnection');
    if (numberConnection === null) {
      return false;
    } else {
      return true;
    }
  }






  isHasVisitedInfoSite() {
    return localStorage.getItem("INFO_SITE_VISITED") == "true";
  }

  getSearchResult(): SearchResults {
    const searchState: SearchState = this.storeService.store.getValue().searchState;
    return searchState.searchResults;
  }

  getTotalItemsMessageAction(): number {
    return this.getSearchResult() != null ? this.getSearchResult().totalItemsMessageAction : 0;
  }


  getAllReferencesData(): ReferencesDataState {
    const referencesDataState = this.storeService.store.getValue().referencesDataState;
    return referencesDataState.messageTypesState == undefined || referencesDataState.messageTypesState.length == 0 ||
      referencesDataState.senderApplicationsState == undefined || referencesDataState.senderApplicationsState.length == 0 ||
      referencesDataState.senderOrganizationsState == undefined || referencesDataState.senderOrganizationsState.length == 0 ? null : referencesDataState;
  }


  getOrganizationById(organizationid: string): SenderOrganizationWithRegistryConfig {
    const referenceDataState: ReferencesDataState = this.getAllReferencesData();
    if (referenceDataState != null && referenceDataState.senderOrganizationsState != null) {
      const name = referenceDataState.senderOrganizationsState.find(x => x.enterpriseNumber == organizationid);
      return name !== undefined ? name : null;
    }

    return null;
  }

  getOrganizationNameById(organizationid: string): LocalizedString {

    const name = this.getOrganizationById(organizationid);
    return name !== null ? name.name : null;


  }

  getApplicationNameById(applicationId: string): LocalizedString {
    const referenceDataState: ReferencesDataState = this.getAllReferencesData();
    if (referenceDataState != null && referenceDataState.senderApplicationsState != null) {
      const senderApplication = referenceDataState.senderApplicationsState.find(x => x.id == applicationId);
      return senderApplication !== undefined ? senderApplication.description : null;
    }
  }

  getMessageTypeById(id: string): LocalizedString {
    const referenceDataState: ReferencesDataState = this.getAllReferencesData();
    if (referenceDataState != null && referenceDataState.messageTypesState != null) {
      const name = referenceDataState.messageTypesState.find(x => x.id == id);
      return name !== undefined ? name.description : null;
    }
  }



  /**
   * Register a state listener and run the listener once on current state value.
   *
   * @param param
   */
  register(listener: (state: StoreShape) => void) {
    const ret = this.storeService.store.subscribe(listener);
    listener(this.storeService.store.getValue());
    return ret;
  }

  getLanguage() {
    return this.storeService.store.getValue().userSession.localeParameter;
  }

  getNumberOfSearchResults(): number {
    const searchResults = this.getSearchResult();
    if (searchResults != null) {
      return searchResults.totalItems;
    }
    else {
      return 0;
    }
  }



  getNumberOfSearchResultPages() {
    return Math.ceil(this.getNumberOfSearchResults() / this.getSearchResultPageSize());
  }

  getSearchResultPageSize() {
    return this.storeService.store.getValue().searchState.pageSize;
  }

  getCurrentPageNumber() {
    const result = this.getSearchResult();
    if (result != null) {
      return result.pageNumber
    }
    else {
      return 1
    }
  }

  isMobile() {
    return this.storeService.store.getValue().isMobile;
  }

  getMessageFilters() {
    return this.storeService.store.getValue().messageFilters;
  }


  subscribeToSearchEvent(listener: (state) => void) {
    // To detect that a search has been started, we check that the search state has not changed
    let searchState = null;
    this.register(state => {
      if (searchState != null && searchState != state.searchState) {
        listener(state);
      }
      searchState = state.searchState;
    });
  }


  getNumberOfPartitionAdmin(): number {
    const adminPartition: Partition[] = this.storeService.store.getValue().pacmanSession.partitions == null ? [] : this.storeService.store.getValue().pacmanSession.partitions.filter(partition => partition.hasAdminRight);
    if (adminPartition == null) { return 0 } else { return adminPartition.length };

  }

  getPacmanModeForms(): string {
    const pacmanStatusForms: PacmanDataForms = this.storeService.store.getValue().pacmanSession.dataForms;
    if (pacmanStatusForms.isAddPartition) return 'addPartition';
    if (pacmanStatusForms.isAddUserToPartition) return 'addUserPartition';
    if (pacmanStatusForms.isEditPartition) return 'editPartition';
    if (pacmanStatusForms.isDeleteUserToPartition) return 'deletePartition';
    return null;

  }

  getPacmanFormsPartitionId(): string {
    return this.storeService.store.getValue().pacmanSession.dataForms.partitionId;
  }

  getAllPartitions(): Partition[] {
    return this.storeService.store.getValue().pacmanSession.partitions;
  }

  getMyPartitionsWithAccess() {
    const allPartitions = this.getAllPartitions();
    return allPartitions == null ? [] : allPartitions.filter(part => part.hasAccess);

  }

  getAllMessageRegistries(): MessageRegistry[] {
    return this.storeService.store.getValue().userSession.allRegistries;
  }

  getTranslatedProvider(providerId: string) {
    if (typeof providerId != 'string') return "";
    const providerRegistry = this.getConfigClient().registries.find(registry => registry.id == providerId) as LocalRegistry;
    if (providerRegistry && !this.appConstants.isNullOrUndefined(providerRegistry.names)) {
      if (!this.appConstants.isNullOrUndefined(providerRegistry.names[this.getLanguage()])) {
        return providerRegistry["names"][this.getLanguage()];
      }
      return providerRegistry.name;
    } else if (providerRegistry) {
      return providerRegistry.name;
    } else {
      let foundName: string;
      this.getAllMessageRegistries().forEach(mr => {
        if (mr.id === providerId) {
          foundName = mr.name[this.getLanguage()];
        }
      });
      if (!this.appConstants.isNullOrUndefined(foundName)) {
        return foundName;
      }
    }
    return providerId;
  }
}
