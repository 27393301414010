import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/browser";
import { EventHint } from "@sentry/browser";
import { ConfigurationServiceExtended } from "./configuration-service-extended";
import { ProviderException } from "../error/CommonException";
import { StateFn } from "../store/StateFn";
import { NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { HashHelper } from "../helper/hashHelper";

@Injectable({
  providedIn: 'root'
})
export class SentryService {

  private initialized = false;
  private logUnknownErrors = false;
  private registriesToLog: string[];

  constructor(private stateFn: StateFn, private router: Router) { }

  public initSentry(logUnknownErrors: boolean, registriesToLog: string[]) {
    if (this.initialized || this.isDisabled(registriesToLog, logUnknownErrors)) {
      return;
    }
    this.registriesToLog = registriesToLog;
    const self = this;
    Sentry.init({
      dsn: 'https://0f6da6c638954dd0a84eee04927bebb6@o452936.ingest.sentry.io/5453993',
      integrations(integrations) {
        return integrations.filter(integration => integration.name !== 'Breadcrumbs');
      },
      enabled: true,
      environment: new ConfigurationServiceExtended().getEnvironment(),
      beforeSend(event, hint) {
        return self.filterEvents(event, hint);
      }
    });
    const id = HashHelper.createHash(this.stateFn.getUser().ssin) + "";
    Sentry.setUser({
      id: id,
      ip_address: "{{auto}}"
    })
    Sentry.configureScope(scope => scope.setTransactionName(window.location.pathname));
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe(navStart => Sentry.configureScope(scope => scope.setTransactionName((navStart as NavigationStart).url)));
    this.initialized = true;
  }

  private isDisabled(registriesToLog: string[], logUnknownErrors: boolean) {
    return registriesToLog.length == 0 && !logUnknownErrors;
  }

  private filterEvents(event: Sentry.Event, hint: EventHint): Sentry.Event {
    if (hint.originalException instanceof ProviderException) {
      const providerException = hint.originalException as ProviderException;
      if (this.registriesToLog.find(p => p === providerException.providerId)) {
        event.fingerprint = ["DP_".concat(providerException.providerId).concat('_').concat(String(providerException.code))];
        return event;
      } else {
        return null;
      }
    }
    return this.logUnknownErrors ? event : null;
  }
}
