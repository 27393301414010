const TECHNICALDATAKEYS: string[] = ["DOC_TYPE", "EBOX_REST_SEQUENCE", "EBOX_REST_TICKET"];

export class BusinessDataHelper {

  public static isTechnicalData(key: string) : boolean {
    return TECHNICALDATAKEYS.includes(key);
  }

  public static isBusinessData(key: string) : boolean {
    return TECHNICALDATAKEYS.includes(key);
  }
}
