<div data-testid="manualPublishId" class="mb-5">
    <div class="container-fluid container-xxxxxl">
        <div class='row'>
            <p class="me-sm-0 ms-sm-0 mt-0 mb-2 w-100 d-block"
                *ngIf="false && stateFnFe.isAccessConsultation()"><a
                    (click)="backResultMessage()" class="returnLink"
                    [href]="sanitize('javascript:void(0)')">{{"search.return" |
                    translate }}</a></p>

            <div
                class="{{isMobile ? 'd-block': 'd-flex align-items-center'}} border-bottom w-100 ms-sm-0 me-sm-0">
                <h2 class="ps-0 pt-0 pb-0  m-0">{{'form.label.manualPublish' |
                    translate}}</h2>
            </div>
            <form
                *ngIf="statusSendingForm === null || (statusSendingForm==StatusFormsEnum.error && manualPublicationForm.errors)"
                [formGroup]="manualPublicationForm"
                (ngSubmit)="onMailFormDataSubmit()" accept-charset="utf-8"
                [attr.aria-label]="'form.label.manualPublish'  | translate "
                class="w-100 {{(manualPublicationForm.status ==='INVALID') ? 'was-validated':''}}"
                method="post" enctype="multipart/form-data">

                <div class="card mb-3 mt-3 col col-xl-8 p-0 alert-danger"
                    *ngIf="manualPublicationForm.errors != null && manualPublicationForm.errors.manualPublicationFormHttpError != undefined"
                    role="alert">
                    <div class="card-header text-danger">
                        <h4 class="alert-heading">{{'form.status.error' |
                            translate}}</h4>
                    </div>
                    <div class="card-body">
                        <div class=" d-block d-sm-flex align-items-center">

                            <p>
                                <ng-container
                                    *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError=='empty'">
                                    {{'form.message.label' | translate}} ({{'form.message.error.empty'
                                    | translate}})
                                </ng-container>
                                <ng-container
                                    *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError=='mimetype' || manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError=='maxSize'">
                                    {{'form.file.error.'+manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError
                                    |
                                    translate:{value1:(manualPublicationForm.errors.manualPublicationFormHttpError.args
                                    == undefined?'':': '.concat(manualPublicationForm.errors.manualPublicationFormHttpError.args[0]))}
                                    }}
                                </ng-container>
                                <ng-container
                                    *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError=='error'">
                                    {{'form.status.generalError' | translate}}
                                </ng-container>

                            </p>

                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="senderPartitionInput" id="labelSenderPartition"
                        [class]="(manualPublicationForm.controls.senderPartitionInput.errors && manualPublicationForm.controls.senderPartitionInput.errors.senderPartitionError) ? 'col-12 invalid-field' : 'col-12' ">
                        <span>{{'form.sender.partition.label' | translate}}</span>
                        <div role="alert" class="invalid-feedback"
                            id="errorSenderPartition">
                            <ng-container
                                *ngIf="manualPublicationForm.controls.senderPartitionInput.errors && manualPublicationForm.controls.senderPartitionInput.errors.senderPartitionError">
                                <span class="d-block">({{manualPublicationForm.controls.senderPartitionInput.errors.senderPartitionError|
                                    translate }} <ng-container
                                        *ngIf="manualPublicationForm.controls.senderPartitionInput.errors.senderPartitionError =='errorFormat'">"{{'form.sender.partition.description' | translate}}"</ng-container>)
                                </span>
                            </ng-container>
                        </div>
                    </label>
                    <div class="col-lg-6 col-xl-4">
                        <select id="senderPartitionInput" class="form-select"
                            formControlName="senderPartitionInput"
                            data-testid="manualPublishPartition">
                            <option *ngFor="let partition of myPartitions"
                                [value]="partition.id">{{partition.id |
                                partitionNamePipe}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group ">
                    <label for="destinationEnterpriseInput"
                        id="labelDestinationEnterprise"
                        [class]="(manualPublicationForm.controls.destinationEnterpriseInput.errors && manualPublicationForm.controls.destinationEnterpriseInput.errors.destinationEnterpriseError) ? 'col-12 invalid-field' : 'col-12' ">
                        <span>{{'form.destination.enterprise.label' |
                            translate}}</span>
                        <a href="javascript:void(0)" placement="top-left"
                            triggers="click" [autoClose]="'outside'"
                            [ngbTooltip]="destinationEnterpriseDesc"
                            class="d-inline-block align-items-center justify-content-center me-1"
                            aria-describedby="destinationEnterpriseDesc">
                            <ng-template #destinationEnterpriseDesc
                                id="destinationEnterpriseDesc">
                                {{'form.destination.enterprise.description' |
                                translate}}
                            </ng-template>
                        </a>
                        <div role="alert" class="invalid-feedback"
                            id="errorDestinationEnterprise">
                            <ng-container
                                *ngIf="manualPublicationForm.controls.destinationEnterpriseInput.errors && manualPublicationForm.controls.destinationEnterpriseInput.errors.destinationEnterpriseError">
                                <span class="d-block">({{'form.destination.enterprise.error.'.concat(manualPublicationForm.controls.destinationEnterpriseInput.errors.destinationEnterpriseError)|
                                    translate }} <ng-container
                                        *ngIf="manualPublicationForm.controls.destinationEnterpriseInput.errors.destinationEnterpriseError =='errorFormat'">"{{'form.destination.enterprise.description' | translate}}"</ng-container>)
                                </span>
                            </ng-container>
                        </div>({{'form.required.label' | translate}})
                    </label>
                    <div class=" col-lg-6 col-xl-4">
                        <input type="text" class="form-control"
                            id="destinationEnterpriseInput"
                            aria-labelledby="labelDestinationEnterprise errorDestinationEnterprise"
                            formControlName="destinationEnterpriseInput"
                            autocomplete="true"
                            aria-describedby="destinationEnterpriseDesc"
                            data-testid="manualPublishKbo" />
                        <small class="mt-0 form-text text-muted"><a
                                [href]="'form.destination.enterprise.helper.href' |  translate"
                                target="_blank" class="external-link">{{'form.destination.enterprise.helper.label'
                                | translate}}</a></small>
                    </div>
                </div>
                <div class="form-group  col-lg-6 col-xl-4">
                    <label for="titleOfMessageInput"
                        id="labelTitleOfMessageInput"
                        [class]="(manualPublicationForm.controls.titleOfMessageInput.errors && manualPublicationForm.controls.titleOfMessageInput.touched) ? 'invalid-field' : null "><span>{{'form.title.label'
                            | translate}}</span>
                        <div role="alert" class="invalid-feedback"
                            id="errorTitleOfMessageInput">
                            <ng-container
                                *ngIf="manualPublicationForm.controls.titleOfMessageInput.errors && manualPublicationForm.controls.titleOfMessageInput.touched">
                                <span class="d-block"
                                    *ngIf="manualPublicationForm.controls.titleOfMessageInput.errors.maxlength">
                                    ({{'form.title.error.errorSize' |
                                    translate:{value1:manualPublicationForm.controls.titleOfMessageInput.errors.maxlength.requiredLength}
                                    }})</span>
                                <span class="d-block"
                                    *ngIf="manualPublicationForm.controls.titleOfMessageInput.errors.required">
                                    ({{'form.title.error.empty' | translate }})</span>
                            </ng-container>
                        </div> ({{'form.required.label' | translate}})
                    </label>
                    <div>
                        <input type="text" class="form-control"
                            id="titleOfMessageInput"
                            aria-labelledby="labelTitleOfMessageInput errorTitleOfMessageInput"
                            formControlName="titleOfMessageInput"
                            autocomplete="true" data-testid="manualPublishTitle" />
                    </div>
                </div>
                <div class="form-group  col-xl-8">
                    <label for="textareaId" id="labelTextareaId"
                        [class]="(manualPublicationForm.controls.messageTextarea.errors && manualPublicationForm.controls.messageTextarea.touched) ? 'invalid-field' : null ">
                        <span>{{'form.message.label' | translate}}</span>
                        <div role="alert" class="invalid-feedback"
                            id="errorMessage">
                            <ng-container
                                *ngIf="manualPublicationForm.controls.messageTextarea.errors && manualPublicationForm.controls.messageTextarea.touched">
                                <span class="text-block"> ({{'form.message.error.empty'
                                    | translate}})</span>
                            </ng-container>
                        </div> ({{'form.required.label' | translate}})</label>
                    <textarea formControlName="messageTextarea" cols="38"
                        rows="9" aria-labelledby="labelTextareaId errorMessage"
                        id="textareaId" class="form-control"
                        [title]="'form.message.label' | translate"
                        data-testid="manualPublishTextarea"></textarea>
                </div>
                <div class="form-group col">
                    <label
                        [class]="(manualPublicationForm.controls.attachmentMessage.errors && manualPublicationForm.controls.attachmentMessage.errors.attachmentMessageError) ? 'invalid-field' : null ">

                        <span class="me-1">{{'form.file.label' | translate}}</span>
                        <a href="javascript:void(0)" placement="top-left"
                            triggers="click" [autoClose]="'outside'"
                            [ngbTooltip]="tipMimetype"
                            class="d-inline-block align-items-center justify-content-center me-1"
                            aria-describedby="tipMimetype">
                            <sup><i class="fas fa-info-circle fa-lg"
                                    aria-hidden="true"
                                    [attr.aria-label]="'form.file.mimetype' | translate"></i></sup>
                            <ng-template #tipMimetype id="tipMimetype">
                                {{('form.file.mimetype' | translate).concat(': ').concat(mimeTypeAuthorized.join(', '))}}
                            </ng-template>
                        </a>
                        <div role="alert" class="invalid-feedback"
                            id="errorAttachmentMessage">
                            <ng-container
                                *ngIf="manualPublicationForm.controls.attachmentMessage.errors && manualPublicationForm.controls.attachmentMessage.errors.attachmentMessageError != null">
                                <span class="d-block"
                                    *ngFor="let error of manualPublicationForm.controls.attachmentMessage.errors.attachmentMessageError">{{'form.file.error.'+error.typeOfError
                                    | translate:{value1:" ".concat(error.args[0])}
                                    }}</span>

                            </ng-container>
                            <span class="d-block"
                                *ngIf="manualPublicationForm.errors && manualPublicationForm.errors.manualPublicationFormHttpError">
                                <ng-container
                                    *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.args !==undefined">
                                    <ng-container
                                        *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.args.length == 1">
                                        {{'form.file.error.'+manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError
                                        | translate:{value1:': \"'.concat(manualPublicationForm.errors.manualPublicationFormHttpError.args[0]).concat('"')}
                                        }}
                                    </ng-container>
                                    <ng-container
                                        *ngIf="manualPublicationForm.errors.manualPublicationFormHttpError.args.length > 1">
                                        {{'form.file.error.'+manualPublicationForm.errors.manualPublicationFormHttpError.typeOfError
                                        | translate:{value1:''} }} :
                                        <ul>
                                            <li
                                                *ngFor="let arg of manualPublicationForm.errors.manualPublicationFormHttpError.args">
                                                "{{arg}}"
                                            </li>
                                        </ul>
                                    </ng-container>
                                </ng-container>
                            </span>

                        </div>
                    </label>
                    <div class="mt-1">
                        <input
                            [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                            formControlName="attachmentMessage"
                            aria-labelledby="errorAttachmentMessage customFileLang"
                            type="file" (change)="onFileChange($event)"
                            class="d-none" id="customFileLang"
                            [lang]="language" multiple />

                        <label
                            [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                            for="customFileLang" id="labelCustomFileLang"
                            class="d-inline-block "
                            (keydown)="onKeyPress($event)">
                            <span
                                tabindex="0"
                                [attr.disabled]="attachments != null && attachments.length>=maxNumberFile ? true : null"
                                class="btn btn-secondary {{ attachments != null && attachments.length>=maxNumberFile ? 'disabled' : ''}}">
                                {{'form.file.button' | translate}}
                            </span>
                        </label>
                        <small
                            class="mt-2 mt-lg-0 ms-lg-2 form-text text-muted d-inline-block"><span
                                [innerHTML]="'form.file.maxSize.enterprise' | translate:{value1:maxSizeFile,value2:maxNumberFile}"></span></small>
                        <ul
                            *ngIf="attachments != undefined && attachments.length > 0"
                            class="attachmentListForm list-unstyled g-0 mt-2">
                            <li
                                *ngFor="let file of getAttachments(); let i = index"
                                class="list-inline-item">
                                <a [href]="sanitize('javascript:void(0)')"
                                    (click)="removeAttachment(i)"
                                    class="d-flex justify-content-between align-items-center bg-white"
                                    [title]="file.fileName">
                                    <app-attachment [attachment]="file"
                                        class="d-inline-flex"></app-attachment>
                                    <span class="file-size text-end pe-0 ps-0">({{file.fileSize
                                        | fileSize}})</span>
                                    <i
                                        class="fas fa-trash-alt text-primary ms-2"
                                        aria-hidden="true"></i>
                                    <span class="visually-hidden">{{'form.file.remove'
                                        | translate }}</span></a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="col">

                    <button [disabled]="errorsForms || !isUploaded"
                        type="submit" class="btn btn-primary"
                        [title]="'form.action.submit.label' | translate"
                        data-testid="manualPublishSubmit"><i
                            class="far fa-envelope"></i> {{'form.action.submit.label'
                        | translate}}
                    </button>
                </div>
            </form>

        </div>
        <div class="{{isMobile ? '':'row'}}">
            <div
                *ngIf="statusSendingForm !== null && (statusSendingForm == StatusFormsEnum.failed || statusSendingForm == StatusFormsEnum.success) "
                class="col-12 p-sm-0 {{isMobile ? 'ps-0 pe-0' :''}}">
                <div role="alert"
                    class="ms-0 alert alert-{{statusSendingForm == StatusFormsEnum.success ?'success':'danger'}} text-body mt-2 col-xl-8 ">
                    <p
                        [innerHTML]="'form.status.'+(statusSendingForm == StatusFormsEnum.success ?'success':'generalError') | translate:{value1:(statusSendingForm == StatusFormsEnum.success) ? (resultForm.sender.companyName !=null ? resultForm.sender.companyName :
                        resultForm.sender.value) : ''}"></p>
                </div>
            </div>
        </div>
    </div>
</div>
