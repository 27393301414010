import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DatePipe, DOCUMENT } from '@angular/common';

import { APP_VERSION } from './app-version';
import { ConfigurationService } from '@smals/ngx-configuration-service';

import { ActionsService, LoadingService, ResponsiveService, SetPositionFixedService } from './services';
import { OpenIdService } from './auth/openIdService';
import { EnterprisePreferencesService } from './services/enterprise-preferences.service';
import { StateFn } from './store/StateFn';
import { NGXLogger } from "ngx-logger";
import { LANGUAGE_LOCALSTORAGE_KEY } from './store/store-data-interface';
import { StoreService } from './store/store.service';
import { RegistriesApi } from './registries/registries-api';
import { SearchAndFilterRequest } from './store/actions-store';
import { MessageListUrlParameters } from './components/messages-from-ebox/MessageListUrlManagement';
import { filter } from 'rxjs/operators';
import { configNGXLogger } from './app.module';
import { PacmanActionService } from './pacman/services/pacmanAction.service';
import { CacheStorageService } from "./services/cacheStorage.service";
import { NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FlowStep, OptinflowComponent } from "./components/optinFlow/optinflow.component";
import { PacmanActionStore } from './pacman/store/pacmanActionStore';
import { HttpMethod, WSHelper } from "./helper/WSHelper";
import { SentryService } from "./services/sentry.service";
import { MessageRegistryService } from './services/message-registry.service';
import { LogConfig } from "./error/log-config";
import { BehaviorSubject } from 'rxjs';
import { Partition } from './pacman/interfaces/partition';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  title: string;
  version: string;
  releaseTime: string;
  private _defaultDataLoaded: boolean;

  //url: string;
  public isMobile: boolean;
  isStateUseDp: boolean;
  public currentPage: BehaviorSubject<string>;
  private modalOptinFlow: NgbModalRef;
  constructor(
    private logger: NGXLogger, private openIdService: OpenIdService, private _configService: ConfigurationService, private stateFn: StateFn,
    private responsiveService: ResponsiveService, private router: Router,
    private positionFixedService: SetPositionFixedService, private enterprisePreferencesService: EnterprisePreferencesService,
    private actionService: ActionsService, @Inject(DOCUMENT) private document: Document,
    private _pacmanActionsStore: PacmanActionStore, private _storeService: StoreService, private _messageRegistryService: MessageRegistryService,
    private _pacmanActionService: PacmanActionService, private _cacheStorageService: CacheStorageService,
    private modalService: NgbModal,
    public datepipe: DatePipe,
    private _loadingService: LoadingService,
    private wsHelper: WSHelper,
    private sentryService: SentryService
  ) {
    configNGXLogger.level = this._configService.getEnvironmentVariable('logLevel');
    if (this._configService.getEnvironment() == 'prod') configNGXLogger.enableSourceMaps = false;
    this.logger.updateConfig(configNGXLogger);
    // this._translate.setDefaultLang('fr');
    this.currentPage = new BehaviorSubject("/");


    //active reload page for second click
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (this.isMobile) {
      this.positionFixedService.refreshPositioning();
    }
  }

  // This field is there to trigger a full redraw of the component when the
  // subsystem state changes


  ngOnInit() {






    if (window.location.pathname == "/reset") {

      this._cacheStorageService.clearLocalStorageAndKeepOptin(this._storeService.store.getValue().userSession?.user?.cbeNumber)
      sessionStorage.clear();
      window.location.pathname = "/";
      window.location.reload();
      return;
    }

    this.title = 'Ebox Enterprise';
    this.version = APP_VERSION.version;
    this.releaseTime = '';
    this.responsiveService.registerToChange(this);

    // INIT language
    let lang = localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY);
    if (lang == null)
      lang = 'nl';
    this.actionService.changeLanguage(lang);
    this.document.documentElement.lang = lang;
    this.onResize();



    this.router.events.pipe(
      filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
    ).subscribe(async (navEnd: NavigationEnd) => {



      this.router.navigated = false;
      window.scrollTo(0, 0);

      const queryParams = new URLSearchParams(window.location.search);
      let currentPage = navEnd.urlAfterRedirects.substring(1, navEnd.urlAfterRedirects.length);
      if (currentPage.indexOf('?') != -1) {
        currentPage = currentPage.substring(0, currentPage.indexOf("?"));
      }
      if (currentPage.indexOf('/') != -1) {
        currentPage = currentPage.substring(0, currentPage.indexOf("/"));
      }
      if (currentPage.indexOf('#') != -1) {
        currentPage = currentPage.substring(0, currentPage.indexOf("#"));
      }

      this._pacmanActionsStore.updateCurrentPage(currentPage);

      this.openIdService.isAuthenticated().subscribe(resp => {
        if (resp) {
          this.initializeSentry();
          this.loadEnterpriseInformation();
          this._defaultDataLoaded = false;
          this._pacmanActionService.initializeAutoOnboardingMayBeAdminPartition().then(response => {
            this.actionService.setAdminStatus(response.isAdmin);
            this.loadPacmanInformation(response.partitions).then().finally(() => {
              this._messageRegistryService.initProviderRegistry(this._configService.getEnvironmentVariable("urlProviderRegistry"), this._configService.getEnvironmentVariable("maxAPIVersionSupported")).then(() => {
                if (currentPage.startsWith("messages") && !this.stateFn.isAccessOnlyManualPublication()) {
                  this.actionService.loadSearchParamsFromNavBarAndSearch().then(() => this._defaultDataLoaded = true);
                } else if (this.stateFn.isAccessOnlyManualPublication() && !this.stateFn.isCurrentPage("manualPublishMessage")) {
                  this._defaultDataLoaded = true
                  this.router.navigate(["manualPublishMessage"]);
                } else {
                  const params: SearchAndFilterRequest = MessageListUrlParameters.toStateAndSearch(queryParams);
                  if (params !== null && params.filters !== undefined && params.filters.folder != undefined) {
                    this._pacmanActionsStore.switchFolder(params.filters.folder);
                  }
                  this._defaultDataLoaded = true;
                }
              })
            })

          })

        } else if (this.stateFn.isAccessTokenValid()) {
          this.loadEnterpriseInformation();
        }
      });
    });


    this._storeService.store.subscribe(state => {
      if (state.userSession.localDpActive && document.body.id != 'dpLocalEnabled') {
        document.body.id = 'dpLocalEnabled';
        this.isStateUseDp = true;
      } else if (!state.userSession.localDpActive && document.body.id != '') {
        document.body.id = '';
        this.isStateUseDp = false;
      }
    });
    if (this._configService.getEnvironment() === 'acc') {
      this.document.body.classList.add("env-acceptation");
    }
  }

  onResize() {
    this.responsiveService.checkWidth();
  }

  initializeSentry() {
    this.wsHelper.callWs<LogConfig>(this._configService.getEnvironmentVariable('urlMonitoring') + '/logSettings', HttpMethod.GET)
      .then(
        response => this.sentryService.initSentry(response.logWebApp, response.logMessageRegistries)
      ).catch(error => {
        this.logger.warn("initializeSentry %s", error)
      })
  }

  private loadEnterpriseInformation() {
    this.enterprisePreferencesService.getEnterprisePreferences().then(() => {
      if (this.stateFn.isAccessTokenValid() && !this.isErrorPage && this.stateFn.isAccessOptinAndEmailModification()) {
        const store = this._storeService.store.getValue().userSession.user;
        if (!this.modalService.hasOpenModals()) {
          if (null == store.firstConnectionDate && !store.exclusivelyEbox && store.lastReminderDate == null) {
            this.openOptinFlow([FlowStep.optin, FlowStep.email, FlowStep.welcome]);

          } else if (this._cacheStorageService.isDueDateReminder(store.lastReminderDate) && !store.exclusivelyEbox) {
            this.openOptinFlow([FlowStep.optinReminder]);
          } else if (null == store.firstConnectionDate && store.exclusivelyEbox) {
            this.openOptinFlow([FlowStep.email]);
          }
        }
      }
    });
  }

  private loadPacmanInformation(hasAccessAdminPartition: Partition[]): Promise<boolean> {

    return this._pacmanActionService.getMyPartitions(hasAccessAdminPartition).then((partitions: Partition[]) => {
      if (this.stateFn.isAccessOnlyManualPublication()) {
        this._pacmanActionsStore.updateUserRightspartitions(partitions.map(partition => { partition.hasAdminRight = false; return partition }));
        return Promise.resolve(true);
      } else {
        return this._pacmanActionService.updatePartitionsWithUsers(partitions).then(() => Promise.resolve(true))

      }
    })


      .catch(() => Promise.reject(false));


  }

  getEnv() {
    return this._configService.getEnvironment();
  }
  getToolsRegistryInfo() {
    return new RegistriesApi().info();
  }

  get isEboxLoaded(): boolean {
    return this._defaultDataLoaded;
  }

  get isUserLoaded() {
    return this._storeService.store.getValue().userSession.user != null || this._storeService.store.getValue().userSession.accessToken != null;
  }

  get configRegistriesLoaded() {
    return this.stateFn.getConfigRegistriesLoaded();
  }

  get errorLogin(): boolean {
    const error = this._storeService.store.getValue().subsystems.error;
    return error != null && error.type === 'authentication' && (error.code == 401 || error.code == 403 || error.code == 504);
  }

  get showHeader() {
    return (!this.isLoginPage && !this.isInfoPage && this.isUserLoaded) || this.errorLogin;
  }

  get authenticationState() {
    return this.stateFn.getAuthenticationState();
  }

  get isErrorPage() {
    return this.stateFn.isCurrentPage('error');
  }

  get isLoginPage() {
    return this.stateFn.isCurrentPage('login');
  }

  get isInfoPage() {
    return this.stateFn.isCurrentPage('info');
  }

  get isConsultMessage() {
    return this.stateFn.isCurrentPage("messages");
  }

  openOptinFlow(step: FlowStep[]) {

    this.logger.info(step)
    const options: NgbModalOptions = {
      modalDialogClass: 'optinflow',
      keyboard: true,
      backdrop: 'static',
      centered: true,
      windowClass: 'optin',
      size: 'lg',

    };
    options.keyboard = false;
    this.modalOptinFlow = this.modalService.open(OptinflowComponent, options);
    this.modalOptinFlow.shown.subscribe(() => this._loadingService.stop("start open Optin Flow"));
    this.modalOptinFlow.componentInstance.isMobile = true;
    this.modalOptinFlow.componentInstance.incomeStep = step;
    this.modalOptinFlow.result.then((statusFormModal) => {

    }, (rejected => {
      this.logger.info('close modal');
    })).finally(() => {
      if (!this._storeService.store.getValue().subsystems.pacmanConsultationService) {
        this._pacmanActionsStore.checkPacmanConsultationService(true);
        this.router.navigateByUrl("/");
      }
    });
  }

}
